export default {
    required: "Mező kitöltése kötelező",
    email: 'Mező formátuma nem érvényes email cím',
    minLength: "Mező legrövidebb hossza {min} karakter kell, hogy legyen",
    maxLength: "Mező hossza legfeljebb {max} karakter lehet",
    oneOfMany: "Legalább egy megadása szükséges",
    minValue: "Nem lehet kevesebb, mint {min}",
    maxValue: "Nem lehet több, mint {max}",
    economicDirectorateCodeExists: "Ez az azonosító már létezik"
}
